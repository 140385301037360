<template>
  <v-container fluid>
    <v-row
      no-gutters
      justify="start"
      class="mb-5"
    >
      <v-toolbar
        elevation="1"
        style="border-left-width:thick; border-left-style:solid; border-left-color:coral;"
      >
        <v-btn
          icon
          @click="cancel"
        >
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-toolbar-title class="pa-0">
          {{ $t('Edit User') }}
        </v-toolbar-title>
        <v-spacer />
      </v-toolbar>
    </v-row>
    <v-row
      no-gutters
      justify="center"
    >
      <v-card width="1900">
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container
            v-if="user"
            fluid
            pa-0
          >
            <v-toolbar
              elevation="0"
              color="primary"
              dark
            >
              <v-toolbar-title class="pa-0">
                {{ $t('General') }}
              </v-toolbar-title>
              <v-icon :class="`m${rtl ? 'r': 'l'}-2`">
                mdi-information-outline
              </v-icon>
            </v-toolbar>
            <v-row
              justify="center"
              class="pa-5"
            >
              <v-col>
                <v-text-field
                  v-model="username"
                  :label="$t('Username')"
                  readonly
                  filled
                  outlined
                  dense
                />
                <v-text-field
                  v-model="email"
                  :label="$t('Email')"
                  :rules="emailRules"
                  outlined
                  dense
                />
                <language-input
                  v-model="locale"
                  :label="$t('Language')"
                  prepend-icon="mdi-translate"
                  outlined
                  dense
                />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="fullName"
                  :label="$t('Full Name')"
                  :rules="nameRules"
                  outlined
                  dense
                />
                <inputTel
                  v-model="phone"
                  prepend-icon="mdi-phone-outline"
                  :label="$t('Phone Number')"
                  :rules="phoneRules"
                  hint="For example: +972555555555"
                  persistent-hint
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <v-row
              class="ma-2"
              justify="end"
            >
              <v-card-actions class="pa-0">
                <v-btn
                  text
                  @click="cancel"
                >
                  <v-icon left>
                    mdi-close-circle-outline
                  </v-icon>
                  {{ $t('Cancel') }}
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="validate"
                >
                  <v-icon left>
                    mdi-check-circle-outline
                  </v-icon>
                  {{ $t('Save') }}
                </v-btn>
              </v-card-actions>
            </v-row>
          </v-container>
        </v-form>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import InputTel from '@/components/common/InputTel.vue'
import { validateEmail } from '../../common/validations'
import LanguageInput from '@/components/common/LanguageInput.vue'

export default {
  name: 'Edit',
  components: [
    InputTel,
    LanguageInput
  ],
  props: {
    username: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      valid: false,
      user: null,
      email: '',
      locale: '',
      phone: '',
      fullName: '',
      relatedGroups: [],
      nameRules: [v => !!v || 'You must fill in this field'],
      emailRules: [
        v => !!v || 'You must fill in this field',
        v => validateEmail(v) || 'Please enter a valid email'
      ],
      phoneRules: [
        v => !!v || 'You must fill in this field',
        v =>
          /^\+[0-9]?()[0-9](\s|\S)(\d[0-9]{9})$/.test(v) ||
          'Please enter a valid phone number'
      ]
    }
  },
  computed: {
    ...mapGetters(['locales', 'groups', 'rtl'])
  },
  beforeCreate () {
    this.$store.dispatch('fetchGroups')
  },
  created () {
    this.$store.dispatch('getUser', { username: this.username }).then(data => {
      this.user = data
      console.log(data)
      this.email = data.email
      this.locale = data.locale
      this.phone = data.phone_number
      this.fullName = data.name
    })
  },
  methods: {
    cancel () {
      this.$router.go(-1)
    },
    validate () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.$store
        .dispatch('editUser', {
          username: this.username,
          email: this.email,
          locale: this.locale,
          phone: this.phone,
          fullName: this.fullName
        })
        .then(() => {
          this.$router.go(-1)
        })
    }
  }
}
</script>
